'use client';

import React from 'react';

import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthProvider } from '../data/context/authContext';
import { ConfirmationProvider } from '../data/context/confirmationContext';
import { AssessmentProvider } from '../data/context/assessmentContext/assessmentContext';
import SessionActivity from '../components/Authentication/SessionActivity/SessionActivity';
import { SearchProvider } from '../data/context/searchContext';
import { BasketProvider } from '../data/context/basketContext/basketContext';
import { ConsultationReorderProvider } from '../data/context/consultationContext/consultationReorderContext';
import { SiteNavigationProvider } from '../data/context/SiteNavigationContext';

const queryClient = new QueryClient();

const Providers = ({ children }: { children: React.ReactNode }) => (
    <SiteNavigationProvider>
        <AuthProvider>
            <SearchProvider>
                <QueryClientProvider client={queryClient}>
                    <ConfirmationProvider>
                        <AssessmentProvider>
                            <BasketProvider>
                                <ConsultationReorderProvider>{children}</ConsultationReorderProvider>
                            </BasketProvider>
                        </AssessmentProvider>
                        <SessionActivity />
                    </ConfirmationProvider>
                </QueryClientProvider>
            </SearchProvider>
        </AuthProvider>
    </SiteNavigationProvider>
);

export default Providers;
